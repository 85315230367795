import { template as template_b40e5b9f43394dad9af3852d8fd9bdef } from "@ember/template-compiler";
const FKControlMenuContainer = template_b40e5b9f43394dad9af3852d8fd9bdef(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
