import { template as template_d091802adba44d16851f03c30bb6aec8 } from "@ember/template-compiler";
const FKLabel = template_d091802adba44d16851f03c30bb6aec8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
