import { template as template_79d9b03c42374c1bb8d413cd12098b62 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_79d9b03c42374c1bb8d413cd12098b62(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
